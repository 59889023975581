import {
  Box,
  Button,
  CloseIcon,
  colors,
  CommunicationIcon,
  Divider,
  FilterIcon,
  MinusIcon,
  MonthRangeSelector,
  PlusIcon,
  ResetIcon,
  SearchInput,
  Slider,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { Popover, styled, SxProps } from '@mui/material';
import { DateRange } from '@mui/x-date-pickers-pro';
import { assertNever } from '@utils';
import { Dayjs } from 'dayjs';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSchedulerSettings } from '../../context';
import { DepartmentFilter, VehicleTypeFilter } from '../Filters';

type Props = {};

const BoxToolbarContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '2200px',
  minHeight: '56px',
  width: '100%',
  paddingRight: '30px',
  gap: '8px',
}));

const BoxVehicleFilterContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '500px',
  height: '100%',
  padding: '0 12px',
  gap: '12px',
  borderRight: 'solid #CBCBCF 7px',
  background: '#EEF3F3',
}));

const BoxFilterPopoverItem = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  padding: '12px 24px',
  gap: '10px',
}));

const BoxFilterPopoverHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '12px 24px',
}));

const SliderButtonStyles: SxProps = {
  padding: '0',
  minHeight: '28px',
  minWidth: '28px',
  borderRadius: '4px',
};

const FilterButtonStyles: SxProps = {
  width: '40px',
  height: '40px',
  minWidth: '40px',
  borderRadius: '100%',
  padding: '0',
};

const SLIDER_VALUE_MIN: number = 40;
const SLIDER_VALUE_MAX: number = 400;
const SLIDER_VALUE_STEP: number = 20;

type MoveDirection = 'left' | 'center' | 'right';

export const Toolbar: FC<Props> = () => {
  const { t } = useTranslation();
  const {
    setVisibleDate,
    calendarParams,
    updateCalendarParams,
    defaultCalendarDateRange,
    vehicleFilters,
    updateVehicleFilters,
    clearVehicleFilters,
    isDisplayingNotes,
    toggleNotes,
    isToursDataFetching,
  } = useSchedulerSettings();

  const searchValue: string = vehicleFilters.search || '';
  const [moveDirection, setMoveDirection] = useState<MoveDirection>('center');
  const { calendarDateRange } = calendarParams;

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'vehicleFilters',
  });

  useEffect(() => {
    if (!isToursDataFetching) {
      updateVisibleDate();
    }
  }, [isToursDataFetching]);

  function updateVisibleDate(): void {
    switch (moveDirection) {
      case 'center':
        setVisibleDate(new Date());
        break;
      case 'left':
        setVisibleDate(calendarDateRange[0].add(2, 'days').toDate());
        break;
      case 'right':
        setVisibleDate(calendarDateRange[1].subtract(2, 'days').toDate());
        break;

      default:
        assertNever(moveDirection);
    }
  }

  return (
    <BoxToolbarContainer>
      <BoxVehicleFilterContainer>
        <SearchInput
          label={t('findVehicle')}
          searchValue={searchValue}
          onSearchChange={(event) => {
            updateVehicleFilters({ search: event?.target?.value || '' });
          }}
          onSearchClear={() => {
            updateVehicleFilters({ search: '' });
          }}
        />
        <Box>
          <Button
            variant={'secondary'}
            size={'medium'}
            sx={{ ...FilterButtonStyles }}
            {...bindTrigger(popupState)}
          >
            <FilterIcon sx={{ width: '22px', height: '22px' }} />
          </Button>
          <Popover
            {...bindPopover(popupState)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <Stack sx={{ minWidth: '350px' }}>
              <BoxFilterPopoverHeader>
                <Typography variant={'h5'}>{t('vehicleFilter')}</Typography>
                <Button
                  variant={'text'}
                  sx={{ padding: '0', justifyContent: 'end' }}
                  onClick={() => popupState.close()}
                >
                  <CloseIcon
                    sx={{
                      width: '22px',
                      height: '22px',
                      color: colors.oxford['100'],
                    }}
                  />
                </Button>
              </BoxFilterPopoverHeader>
              <Divider variant={'fullWidth'} />
              <BoxFilterPopoverItem>
                <VehicleTypeFilter />
                <DepartmentFilter />
              </BoxFilterPopoverItem>
              <Divider variant={'fullWidth'} />
              <BoxFilterPopoverItem sx={{ maxWidth: '160px' }}>
                <Button
                  variant={'tertiary'}
                  sx={{ padding: '4px 8px', gap: '6px' }}
                  onClick={() => clearVehicleFilters()}
                >
                  <ResetIcon
                    sx={{
                      width: '16px',
                      height: '16px',
                    }}
                  />
                  <Typography variant={'bodyRegularSmall'}>
                    {t('clearFilter')}
                  </Typography>
                </Button>
              </BoxFilterPopoverItem>

              <Divider variant={'fullWidth'} />
            </Stack>
          </Popover>
        </Box>
      </BoxVehicleFilterContainer>

      <Stack spacing={4} direction="row" alignItems="center">
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Button
            variant={isDisplayingNotes ? 'secondary' : 'tertiary'}
            size={'medium'}
            sx={{ borderRadius: '4px' }}
            onClick={toggleNotes}
          >
            <CommunicationIcon sx={{ width: '16px', height: '16px' }} />
          </Button>
          <Button
            variant={'tertiary'}
            size={'medium'}
            sx={{ borderRadius: '4px' }}
            disabled={isToursDataFetching}
            onClick={() => {
              setMoveDirection('center');
              setVisibleDate(new Date());
            }}
          >
            <Typography variant={'bodyRegularSmall'}>{t('today')}</Typography>
          </Button>

          <MonthRangeSelector
            label={t('calendarRange')}
            dateRangeValue={calendarParams.calendarDateRange}
            onChange={(
              dateRange: DateRange<Dayjs>,
              newDirection: MoveDirection
            ) => {
              updateCalendarParams({
                ...calendarParams,
                calendarDateRange: [dateRange[0], dateRange[1]],
              });
              setMoveDirection(newDirection);
              toast.success(
                t('nowDisplayingFromTo', {
                  from: dateRange[0].format('MMM YYYY'),
                  to: dateRange[1].format('MMM YYYY'),
                }),
                {
                  autoClose: 2000,
                  closeButton: true,
                }
              );
            }}
            buttonStyles={{ borderRadius: '4px' }}
            isButtonContentDisabled={false}
            resetValue={defaultCalendarDateRange}
            isSwitchIconDisabled={true}
            monthsLimit={{
              back: 3,
              forward: 1,
            }}
          />
        </Box>

        <Stack
          spacing={4}
          direction="row"
          alignItems="center"
          sx={{ minWidth: 200 }}
        >
          <Button
            variant={'tertiary'}
            sx={{ ...SliderButtonStyles }}
            onClick={() => {
              const nextValue: number =
                calendarParams.sliderPosition - SLIDER_VALUE_STEP;
              if (nextValue < SLIDER_VALUE_MIN) {
                return;
              }
              updateCalendarParams({
                ...calendarParams,
                sliderPosition: nextValue,
              });
            }}
          >
            <MinusIcon />
          </Button>

          <Slider
            value={calendarParams.sliderPosition}
            min={SLIDER_VALUE_MIN}
            max={SLIDER_VALUE_MAX}
            step={SLIDER_VALUE_STEP}
            onChange={(_, sliderPosition: number) =>
              updateCalendarParams({ ...calendarParams, sliderPosition })
            }
          />
          <Button
            variant={'tertiary'}
            sx={{ ...SliderButtonStyles }}
            onClick={() => {
              const nextValue: number =
                calendarParams.sliderPosition + SLIDER_VALUE_STEP;
              if (nextValue > SLIDER_VALUE_MAX) {
                return;
              }
              updateCalendarParams({
                ...calendarParams,
                sliderPosition: nextValue,
              });
            }}
          >
            <PlusIcon />
          </Button>
        </Stack>
      </Stack>
    </BoxToolbarContainer>
  );
};
