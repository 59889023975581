import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import {
  CarrierContactType,
  CarrierType,
  PaginatedListType,
  ParamsWithPagination,
  RequestedVehicleType,
  UUIDType,
  VehicleType,
} from '@types';

export const getCarriersAutocomplete = async (
  params
): Promise<CarrierType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    '/v2/carriers/autocomplete/',
    {
      params: params,
    }
  );
  return res.data;
};

export const getVehiclesAutocomplete = async (
  params
): Promise<VehicleType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    '/v2/vehicles/autocomplete/',
    {
      params,
    }
  );
  return res.data;
};

export const getVehicles = async (
  params?: ParamsWithPagination<{
    carrier?: number;
    contract?: string;
    department?: UUIDType;
    search?: string;
    vehicle_type?: RequestedVehicleType | '';
    active_department: boolean;
  }>
): Promise<PaginatedListType<VehicleType>> => {
  const res = await axiosAuthenticatedRequest.get<
    PaginatedListType<VehicleType>
  >('/v2/vehicles/', {
    params: { ...params },
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });

  return res.data;
};

export const getVehicle = async (vehicleUUID: string) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/vehicles/${vehicleUUID}/`
  );
  return res.data;
};

export const createVehicle = async (data) => {
  const res = await axiosAuthenticatedRequest.post(`/v2/vehicles/`, data);
  return res.data;
};

export const getCarrierContacts = async (
  carrierUUID: UUIDType
): Promise<CarrierContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/carriers/${carrierUUID}/contacts/`
  );
  return res.data;
};
