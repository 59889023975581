import { moveOrderToAnotherTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { addSelfClearingLoadingToast } from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { UUIDType } from '@types';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSchedulerSettings } from '../../context';

type OrderMoveMutationType = {
  orderUUID: UUIDType;
  targetTourUUID: UUIDType;
};

type Props = {
  popupState: PopupState;
};

const BoxSubtitlesContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const CardContentContainer = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

export const MoveOrderBetweenToursDialog: FC<Props> = ({ popupState }) => {
  const { t } = useTranslation();
  const {
    refetchTours,
    refetchNotes,
    isToursDataFetching,
    refetchOrders,
    clearSelection,
    selectedOrder,
    selectedTour,
  } = useSchedulerSettings();
  const tourUUID: UUIDType = selectedTour?.uuid || '';
  const tourReference = selectedTour?.reference || '';

  const orderUUID: UUIDType = selectedOrder?.uuid || '';
  const orderStatus: UUIDType = selectedOrder?.status || 'DRAFT';
  const orderReference = selectedOrder?.reference || '';

  const { mutateAsync: mutateMoveOrder, isPending: isMutating } = useMutation({
    mutationKey: [QueryKeys.ORDERS, orderUUID],
    mutationFn: (values: OrderMoveMutationType) => {
      const { orderUUID, targetTourUUID } = values;
      return moveOrderToAnotherTour(orderUUID, targetTourUUID);
    },
  });

  async function handleConfirm(): Promise<void> {
    const getToastId = addSelfClearingLoadingToast(t('movingOrder'), 6000);

    mutateMoveOrder(
      { orderUUID, targetTourUUID: tourUUID },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            closeButton: true,
            autoClose: 2000,
            type: 'success',
            render: t('orderWasMovedToTour', {
              orderReference,
              targetTourReference: tourReference,
            }),
            isLoading: false,
          });
        },
        onError: () => {
          toast.update(getToastId(), {
            closeButton: true,
            autoClose: 6000,
            render: t('orderCouldNotBeUpdated'),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: async () => {
          try {
            await Promise.all([
              refetchTours(),
              refetchOrders(),
              refetchNotes(),
            ]);

            popupState.close();
            clearSelection();
          } catch (error) {
            toast.error(t('somethingWentWrong'));
          }
        },
      }
    );
  }

  async function handleCancel(): Promise<void> {
    if (isMutating) {
      return;
    }
    try {
      await Promise.all([refetchTours(), refetchOrders(), refetchNotes()]);
      popupState.close();
      clearSelection();
    } catch (error) {
      toast.error(t('somethingWentWrong'));
    }
  }

  return selectedTour && selectedOrder ? (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
      onClose={handleCancel}
    >
      <DialogTitle onClose={() => handleCancel()}>
        <Typography variant="h3" color="primary">
          {orderReference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {orderStatus}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <BoxSubtitlesContainer>
          <Typography variant="h5">{t('movingOrder')}</Typography>
        </BoxSubtitlesContainer>
        <CardContentContainer variant="filled-green" elevation={0} square>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="bodyRegular">
              {t('movingOrderToTour', {
                orderReference,
                tourReference,
              })}
            </Typography>
          </CardContent>
        </CardContentContainer>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => handleCancel()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={() => handleConfirm()}
            disabled={isToursDataFetching || isMutating}
            loading={isToursDataFetching || isMutating}
            startIcon={<SquareIcon />}
          >
            {!isToursDataFetching ? t('move') : t('loading')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
};
