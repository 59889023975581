import { Box, colors, InfoOutlineIcon, Tooltip, Typography } from '@dizzbo/ui';
import { SxProps, Theme, useTheme } from '@mui/material';
import { ParsedNote } from '@types';
import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { EditNoteDialog } from '../Dialogs';

type Props = {
  parsedNote: ParsedNote;
};

const tooltipLabelStyles: SxProps = {
  fontWeight: 500,
  fontSize: '12px',
  lineHeight: '14px',
  color: colors.oxford[15],
};

const tooltipValueStyles: SxProps = {
  fontWeight: 400,
  fontSize: '15px',
  lineHeight: '20px',
};

export const NoteItem: FC<Props> = ({ parsedNote }) => {
  const { color, note: noteText, createdAt, createdBy } = parsedNote;
  const { t } = useTranslation();
  const theme: Theme = useTheme();
  const editNotePopupState: PopupState = usePopupState({
    variant: 'dialog',
    popupId: 'edit_note_popupState',
  });

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        maxWidth: '100%',
        overflow: 'hidden',
        height: '22px',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '4px 20px',
      }}
      bgcolor={color}
      onDoubleClick={() => {
        editNotePopupState.open();
      }}
    >
      <Typography
        sx={{
          width: '95%',
          maxWidth: '95%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          color: '#000',
          fontSize: `${theme.typography.tiny.fontSize}px !important`,
          fontWeight: `${theme.typography.footnote.fontWeight} !important`,
          lineHeight: `${theme.typography.tiny.lineHeight} !important`,
        }}
      >
        {noteText.trim()}
      </Typography>
      <Box
        sx={{
          width: '5%',
        }}
      >
        <Box
          sx={{
            minWidth: '20px',
            minHeight: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 0,
            cursor: 'default',
          }}
        >
          <Tooltip
            title={
              <Box>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography sx={{ ...tooltipLabelStyles }}>
                    {t('createdBy')}:
                  </Typography>
                  <Typography sx={{ ...tooltipValueStyles }}>
                    {createdBy}
                  </Typography>
                </Box>
                <Box sx={{ textAlign: 'left' }}>
                  <Typography sx={{ ...tooltipLabelStyles }}>
                    {t('createdAt')}:
                  </Typography>
                  <Typography sx={{ ...tooltipValueStyles }}>
                    {createdAt}
                  </Typography>
                </Box>
              </Box>
            }
            placement={'top'}
          >
            <InfoOutlineIcon
              sx={{
                width: '16px',
                height: '16px',
                color: '#000',
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <EditNoteDialog popupState={editNotePopupState} parsedNote={parsedNote} />
    </Box>
  );
};
