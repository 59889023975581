import { SxProps, useTheme } from '@mui/material/styles';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';

import {
  Box,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  colors,
  ListItemButton,
  Menu,
  MenuItem,
  PersonIcon,
  Typography,
} from '@dizzbo/ui';

import { useAuth } from '@dizzbo/core/hooks';
import { DepartmentType } from '@types';
import { useTranslation } from 'react-i18next';
import { useSchedulerSettings } from '../../context';

const FilterTypeButtonStyles: SxProps = {
  display: 'flex',
  gap: '6px',
  minWidth: '130px',
  justifyContent: 'space-between',
  alignItems: 'center',
};

const ButtonContentStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '6px',
};

type Props = {};

export const DepartmentFilter: FC<Props> = () => {
  const theme = useTheme();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'departmentTypeFilter',
  });

  const { vehicleFilters, departmentsData, updateVehicleFilters } =
    useSchedulerSettings();
  const { user } = useAuth();
  const { department } = user;

  const hasActiveFilters: boolean = !!vehicleFilters.department;

  const { t } = useTranslation();

  function getButtonExtraStyles(): SxProps {
    return !!department && !!vehicleFilters.department
      ? {
          boxShadow: hasActiveFilters
            ? `inset 0px 0px 0px 2px ${colors.red[80]};`
            : 'none',
          backgroundColor: colors.red[40],
          '&:hover': {
            backgroundColor: colors.red[60],
          },
        }
      : {
          boxShadow: hasActiveFilters
            ? `inset 0px 0px 0px 2px ${theme.styles.button.primary.focus.boxShadowColor};`
            : 'none',
        };
  }

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        {...bindTrigger(popupState)}
        sx={{
          ...FilterTypeButtonStyles,
          ...getButtonExtraStyles(),
        }}
      >
        <Box sx={{ ...ButtonContentStyles }}>
          <PersonIcon sx={{ width: '16px', height: '16px' }} />
          <Typography variant={'bodyRegularSmall'}>
            {vehicleFilters.department
              ? vehicleFilters.department.name
              : t('department')}
          </Typography>
        </Box>
        <Box sx={{ ...ButtonContentStyles }}>
          {popupState.isOpen ? (
            <ChevronUpIcon sx={{ width: '16px', height: '16px' }} />
          ) : (
            <ChevronDownIcon sx={{ width: '16px', height: '16px' }} />
          )}
        </Box>
      </Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        {departmentsData.results.map((department: DepartmentType) => {
          return (
            <MenuItem key={department.uuid}>
              <ListItemButton
                onClick={() => {
                  updateVehicleFilters({
                    department,
                  });
                  popupState.close();
                }}
              >
                <Typography>{department.name}</Typography>
              </ListItemButton>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
};
