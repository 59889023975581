import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs, isDayjs } from 'dayjs';
import { t } from 'i18next';
import { RequestedVehicleTypeEnum } from '../types';

export function assertNever(value: never) {
  throw new Error('Unexpected value:', value);
}

export function unwrap<T>(
  value: T | null | undefined,
  variableName?: string,
  fileName?: string
): T {
  if (value === null || value === undefined) {
    throw new Error(
      `Error ${fileName ? `in file ${fileName}` : ''}! ${variableName || 'A variable'} is ${value}!`
    );
  }

  return value;
}

export function parseDateRangeData(
  dateRange: DateRange<Dayjs | null>
): DateRange<Dayjs | null> {
  return Array.isArray(dateRange) &&
    dateRange.length === 2 &&
    dateRange[0] &&
    dateRange[0]['$d'] &&
    isDayjs(dateRange[0]) &&
    dateRange[1] &&
    dateRange[1]['$d'] &&
    isDayjs(dateRange[1])
    ? [dayjs(dateRange[0]['$d']), dayjs(dateRange[1]['$d'])]
    : [null, null];
}

export function getVehicleTypeLabel(type: RequestedVehicleTypeEnum): string {
  switch (type) {
    case RequestedVehicleTypeEnum.ALU_TIPPER_TRUCK:
      return t('aluTipperTruck');
    case RequestedVehicleTypeEnum.COIL_TROUGH_TRAILER:
      return t('coilThroughTrailer');
    case RequestedVehicleTypeEnum.FRIGO:
      return t('frigo');
    case RequestedVehicleTypeEnum.JOLODA_TRAILER:
      return t('jolodaTrailer');
    case RequestedVehicleTypeEnum.JUMBO_TRAILER_TRAIN:
      return t('jumboTrailerTrain');
    case RequestedVehicleTypeEnum.KOFFERSATTEL:
      return t('koffersattel');
    case RequestedVehicleTypeEnum.MEGATRAILER:
      return t('megatrailer');
    case RequestedVehicleTypeEnum.OPEN_TRUCK:
      return t('openTruck');
    case RequestedVehicleTypeEnum.PLANE136:
      return t('plane136');
    case RequestedVehicleTypeEnum.SMALL_TRUCK:
      return t('smallTruck');
    case RequestedVehicleTypeEnum.SPRINTER12:
      return t('sprinter12');
    case RequestedVehicleTypeEnum.STANDARD_PLANE:
      return t('standardPlane');
    case RequestedVehicleTypeEnum.STANDARD_TAUTLINER:
      return t('standardTautliner');
    case RequestedVehicleTypeEnum.TIPPER_TRUCK:
      return t('tipperTruck');
    case RequestedVehicleTypeEnum.TRAILER_TRAIN:
      return t('trailerTrain');
    case RequestedVehicleTypeEnum.TRUCK_WITH_TAILLIFT:
      return t('truckWithTaillift');
    case RequestedVehicleTypeEnum.WALKING_FLOOR_TRAILER:
      return t('walkingFloorTrailer');

    default:
      assertNever(type);
      return '';
  }
}
