import { deleteNote, editNote } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  ColorPicker,
  colors,
  DateRangeSelector,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LoadingButton,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useSchedulerSettings } from '@scheduler/context';
import { useMutation } from '@tanstack/react-query';
import { ParsedNote } from '@types';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  popupState: PopupState;
  parsedNote: ParsedNote;
};

export const EditNoteDialog: FC<Props> = ({ popupState, parsedNote }) => {
  const {
    note: currentNote,
    uuid: noteUUID,
    color: currentColor,
    scheduler,
  } = parsedNote;
  const { t } = useTranslation();
  const {
    DEFAULT_NOTE_COLORS,
    DEFAULT_NOTE_RESET_DATE,
    refetchOrders,
    refetchTours,
    refetchNotes,
    isFetching,
  } = useSchedulerSettings();
  const { startsAt, arrivesAt } = scheduler;
  const initialDateRange: DateRange<Dayjs> = [
    dayjs(startsAt),
    dayjs(arrivesAt),
  ];

  const [note, setNote] = useState<string>(currentNote);
  const [color, setColor] = useState<string>(currentColor);
  const [dateRange, setDateRange] =
    useState<DateRange<Dayjs>>(initialDateRange);

  const { mutateAsync: mutateEditNote, isPending: isEditing } = useMutation({
    mutationKey: [QueryKeys.NOTES, noteUUID],
    mutationFn: () => {
      return editNote(noteUUID, note, color, dateRange);
    },
  });

  const { mutateAsync: mutateDeleteNote, isPending: isDeleting } = useMutation({
    mutationKey: [QueryKeys.NOTES, noteUUID],
    mutationFn: () => {
      return deleteNote(noteUUID);
    },
  });

  async function handleDeleteNote(): Promise<void> {
    const getToastId = addSelfClearingLoadingToast(t('deletingNote'), 6000);

    await mutateDeleteNote(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('noteDeleted'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.error(generateAxiosErrorMessage(error), {
          autoClose: 6000,
          closeButton: true,
        });
      },
      onSettled: async () => {
        await Promise.all([refetchTours(), refetchOrders(), refetchNotes()]);
        popupState.close();
      },
    });
  }

  async function handleEditNote(): Promise<void> {
    const getToastId = addSelfClearingLoadingToast(t('editingNote'), 6000);

    await mutateEditNote(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('noteUpdated'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.error(generateAxiosErrorMessage(error), {
          autoClose: 6000,
          closeButton: true,
        });
      },
      onSettled: async () => {
        await Promise.all([refetchTours(), refetchOrders(), refetchNotes()]);
        popupState.close();
      },
    });
  }

  function handleCancel(): void {
    setNote(currentNote);
    setColor(currentColor);
    setDateRange(initialDateRange);
    popupState.close();
  }

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
      onClose={handleCancel}
    >
      <DialogTitle onClose={handleCancel} sx={{ padding: '12px 24px' }}>
        <Typography variant="h3" color="primary">
          {t('editNote')}
        </Typography>
      </DialogTitle>

      <Divider />
      <DialogContent dividers sx={{ padding: '12px 24px' }}>
        <Stack spacing={3}>
          <TextField
            label={t('note')}
            type={'text'}
            value={note}
            onChange={(e) => {
              const value: string = e.target.value;
              if (value.length <= 255) {
                setNote(value);
              }
            }}
            fullWidth
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '0.4fr 0.6fr',
              width: '100%',
            }}
          >
            <Box sx={{ maxWidth: '300px', alignSelf: 'center' }}>
              <DateRangeSelector
                label={t('displayDate')}
                dateRangeValue={dateRange}
                filterValue={null}
                onChange={(dateRange: DateRange<Dayjs>) => {
                  if (dateRange[0].isSame(dateRange[1])) {
                    setDateRange([dateRange[0], dateRange[0].add(1, 'days')]);
                  } else {
                    setDateRange(dateRange);
                  }
                }}
                filters={[]}
                isButtonContentDisabled={false}
                buttonStyles={{ borderRadius: '4px' }}
                resetValue={DEFAULT_NOTE_RESET_DATE}
                maxDateRange={90}
              />
            </Box>

            <ColorPicker
              label={t('selectColor')}
              templateCountInRow={4}
              colors={DEFAULT_NOTE_COLORS}
              onColorChange={(newColor: string) => setColor(newColor)}
              pickedColor={color}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button
            variant="tertiary"
            sx={{ color: colors.red[100] }}
            onClick={handleDeleteNote}
          >
            {t('deleteNote')}
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              gap: '12px',
            }}
          >
            <Button variant="tertiary" onClick={handleCancel}>
              {t('cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleEditNote}
              disabled={isFetching || isEditing || isDeleting}
              loading={isFetching || isEditing || isDeleting}
            >
              {!isEditing && !isDeleting ? t('save') : t('loading')}
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
