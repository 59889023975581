import { useTheme } from '@mui/material/styles';
import { useQuery } from '@tanstack/react-query';
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React from 'react';

import { getColleagues, getTeams } from '@core/api';
import { QueryKeys } from '@core/config';
import { useWorkspaces } from '@dizzbo/core/hooks';
import { useAuth } from '@dizzbo/core/hooks/useAuth';
import { Button, ChevronDownIcon, Divider, Menu, PersonIcon } from '@dizzbo/ui';

import { FilterMenuList } from './FilterMenuList';

export const ContributorsFilter: React.FC = () => {
  const theme = useTheme();
  const { user } = useAuth();

  const popupState = usePopupState({
    variant: 'dialog',
    popupId: 'orderListContributorsFilter',
  });

  const { activeWorkspace } = useWorkspaces();

  // Teams Filters
  // --------------------------------------------------
  const teamsFilterValues = activeWorkspace?.settings?.filters?.team || [];

  const { data: teamsData } = useQuery({
    queryKey: [QueryKeys.TEAMS],
    queryFn: () => getTeams(),
    staleTime: 1000 * 60,
  });

  const teamsFilters = teamsData?.results
    ? teamsData.results.map((team) => ({
        name: team.name,
        value: team.uuid,
      }))
    : [];

  const teamFiltersConfig = [
    {
      groupName: 'filters_teams',
      key: 'team',
      filters: teamsFilters,
    },
  ];

  // --------------------------------------------------

  // Assignee Filters
  // --------------------------------------------------
  const assigneeFiltersValues =
    activeWorkspace?.settings?.filters?.assignee || [];
  const { department } = user;
  const { data: teammatesData } = useQuery({
    queryKey: [QueryKeys.TEAMMATES],
    queryFn: () => getColleagues(department),
    staleTime: 1000 * 60,
  });
  const teammatesFilters = teammatesData?.results
    ? teammatesData.results.map((teammate) => ({
        name: teammate.displayName,
        value: teammate.uuid,
      }))
    : [];
  const assigneesFiltersConfig = [
    {
      groupName: 'filters_assignee',
      key: 'assignee',
      filters: [
        {
          name: 'filters_assignedToMe',
          value: user.uuid,
        },
        ...teammatesFilters,
      ],
    },
  ];

  // --------------------------------------------------

  const hasActiveFilters =
    teamsFilterValues.length > 0 || assigneeFiltersValues.length > 0;

  return (
    <>
      <Button
        variant="secondary"
        size="medium"
        startIcon={<PersonIcon />}
        endIcon={<ChevronDownIcon />}
        {...bindTrigger(popupState)}
        sx={{
          boxShadow: hasActiveFilters
            ? `inset 0px 0px 0px 2px ${theme.styles.button.primary.focus.boxShadowColor};`
            : 'none',
        }}
      ></Button>
      <Menu
        {...bindMenu(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        slotProps={{
          paper: { sx: { minWidth: 264 } },
        }}
      >
        <FilterMenuList
          filterType="assignee"
          filterConfig={assigneesFiltersConfig}
          initialFilterValues={assigneeFiltersValues}
        />
        <Divider />
        <FilterMenuList
          filterType="team"
          filterConfig={teamFiltersConfig}
          initialFilterValues={teamsFilterValues}
        />
      </Menu>
    </>
  );
};
