import { addNote } from '@core/api';
import { QueryKeys } from '@core/config';
import {
  addSelfClearingLoadingToast,
  generateAxiosErrorMessage,
} from '@dizzbo/core';
import {
  Box,
  Button,
  ColorPicker,
  DateRangeSelector,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  LoadingButton,
  Stack,
  TextField,
  Typography,
} from '@dizzbo/ui';
import { DateRange } from '@mui/x-date-pickers-pro';
import { useSchedulerSettings } from '@scheduler/context';
import { useMutation } from '@tanstack/react-query';
import { UUIDType } from '@types';
import { AxiosError } from 'axios';
import { Dayjs } from 'dayjs';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

type Props = {
  popupState: PopupState;
  vehicle: UUIDType;
};

export const AddNoteDialog: FC<Props> = ({ popupState, vehicle }) => {
  const { t } = useTranslation();
  const {
    DEFAULT_NOTE_COLORS,
    DEFAULT_NOTE_RESET_DATE,
    refetchOrders,
    refetchTours,
    refetchNotes,
    isFetching,
  } = useSchedulerSettings();

  const [note, setNote] = useState<string>('');
  const [color, setColor] = useState<string>(DEFAULT_NOTE_COLORS[0]);
  const [dateRange, setDateRange] = useState<DateRange<Dayjs>>(
    DEFAULT_NOTE_RESET_DATE
  );

  const { mutateAsync: mutateAddNote, isPending: isMutating } = useMutation({
    mutationKey: [QueryKeys.NOTES, vehicle],
    mutationFn: () => {
      return addNote(note, color, vehicle, dateRange);
    },
  });

  async function handleAddNote(): Promise<void> {
    const getToastId = addSelfClearingLoadingToast(t('addingNote'), 6000);

    await mutateAddNote(null, {
      onSuccess: () => {
        toast.update(getToastId(), {
          autoClose: 2000,
          closeButton: true,
          render: t('noteAdded'),
          type: 'success',
          isLoading: false,
        });
      },
      onError: (error: AxiosError) => {
        toast.error(generateAxiosErrorMessage(error), {
          autoClose: 6000,
          closeButton: true,
        });
      },
      onSettled: async () => {
        await Promise.all([refetchTours(), refetchOrders(), refetchNotes()]);
        popupState.close();
      },
    });
  }

  function handleCancel(): void {
    setNote('');
    setColor(DEFAULT_NOTE_COLORS[0]);
    setDateRange(DEFAULT_NOTE_RESET_DATE);
    popupState.close();
  }

  return (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
      onClose={handleCancel}
    >
      <DialogTitle onClose={handleCancel} sx={{ padding: '12px 24px' }}>
        <Typography variant="h3" color="primary">
          {t('addNote')}
        </Typography>
      </DialogTitle>

      <Divider />
      <DialogContent dividers sx={{ padding: '12px 24px' }}>
        <Stack spacing={3}>
          <TextField
            label={t('note')}
            type={'text'}
            value={note}
            onChange={(e) => {
              const value: string = e.target.value;
              if (value.length <= 255) {
                setNote(value);
              }
            }}
            fullWidth
          />
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '0.4fr 0.6fr',
              width: '100%',
            }}
          >
            <Box sx={{ maxWidth: '300px', alignSelf: 'center' }}>
              <DateRangeSelector
                label={t('displayDate')}
                dateRangeValue={dateRange}
                filterValue={null}
                onChange={(dateRange: DateRange<Dayjs>) => {
                  setDateRange(dateRange);
                }}
                filters={[]}
                isButtonContentDisabled={false}
                buttonStyles={{ borderRadius: '4px' }}
                resetValue={DEFAULT_NOTE_RESET_DATE}
                maxDateRange={90}
              />
            </Box>

            <ColorPicker
              label={t('selectColor')}
              templateCountInRow={4}
              colors={DEFAULT_NOTE_COLORS}
              onColorChange={(newColor: string) => setColor(newColor)}
            />
          </Box>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-around',
              gap: '12px',
            }}
          >
            <Button variant="tertiary" onClick={() => handleCancel()}>
              {t('cancel')}
            </Button>
            <LoadingButton
              type="submit"
              variant="primary"
              onClick={handleAddNote}
              disabled={isFetching || isMutating}
              loading={isFetching || isMutating}
            >
              {!isMutating ? t('save') : t('loading')}
            </LoadingButton>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
