import SvgIcon from '@mui/material/SvgIcon';
import React, { ComponentProps, FC } from 'react';
import { Box } from '../Box';

type MuiSvgIconProps = ComponentProps<typeof SvgIcon>;

export const AddCommentIcon: FC<MuiSvgIconProps> = (props) => {
  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <SvgIcon {...props}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 4H2V17.5H4.5V20.118L9.73607 17.5H13V15.5H9.26393L6.5 16.882V15.5H4V6H20V9H22V4ZM18.0769 18V15.9231H16V14.0769H18.0769V12H19.9231V14.0769H22V15.9231H19.9231V18H18.0769Z"
            fill="#737E90"
          />
        </svg>
      </SvgIcon>
    </Box>
  );
};

AddCommentIcon.displayName = 'AddCommentIcon';
