import { SxProps } from '@mui/material';
import React, { FC, useState } from 'react';
import { Box } from '../Box';
import { CheckIcon } from '../icons';
import { Typography } from '../Typography';

type Props = {
  colors: Array<string>;
  templateCountInRow?: number;
  pickedColor?: string;
  label?: string;
  sx?: SxProps;
  onColorChange: (newColor: string) => void;
};

type ColorPresetProps = {
  color: string;
  handleColorUpdate: (newColor: string) => void;
  isSelected: boolean;
};

export const ColorPicker: FC<Props> = ({
  colors,
  templateCountInRow = 5,
  sx,
  label,
  pickedColor = colors[0],
  onColorChange,
}) => {
  const [selectedColor, setSelectedColor] = useState<string>(
    pickedColor || '#fff'
  );

  function updateSelectedColor(newColor: string): void {
    setSelectedColor(newColor);
    onColorChange(newColor);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 4px',
        maxWidth: '300px',
        borderRadius: '12px',
        gap: '8px',
        alignItems: 'center',
        justifyContent: 'center',
        ...sx,
      }}
    >
      {label ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '0 12px',
          }}
        >
          <Typography variant={'bodyRegular'}>{label}</Typography>
        </Box>
      ) : null}
      <Box
        sx={{
          display: 'grid',
          width: '100%',
          height: '100%',
          padding: '6px',
          gap: '6px',
          justifyContent: 'space-around',
        }}
        gridTemplateColumns={`repeat(${templateCountInRow}, 1fr)`}
      >
        {colors.map((color: string) => {
          return (
            <ColorPreset
              color={color}
              handleColorUpdate={updateSelectedColor}
              isSelected={selectedColor === color}
              key={color}
            />
          );
        })}
      </Box>
    </Box>
  );
};

const ColorPreset: FC<ColorPresetProps> = ({
  color,
  isSelected,
  handleColorUpdate,
}) => {
  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      bgcolor={color}
      border={isSelected ? '2px solid #000' : 'none'}
      onClick={() => handleColorUpdate(color)}
    >
      {isSelected ? <CheckIcon sx={{ width: '16px', height: '16px' }} /> : null}
    </Box>
  );
};
