import { styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import React, { FC } from 'react';

import {
  AddCommentIcon,
  Box,
  Button,
  InfoOutlineIcon,
  LicensePlate,
  Tooltip,
  TruckIcon,
  Typography,
} from '@dizzbo/ui';
import { getVehicleTypeLabel } from '@utils';
import { PopupState, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { AddNoteDialog } from '../Dialogs';

const ContainerStyles = styled(Box)(({ theme }) => ({
  height: '100%',
  padding: 12,
  minWidth: '100%',
  display: 'flex',
  backgroundColor: theme.palette.turquoise[20],
}));

const ContentStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'end',
  gap: '8px',
}));

const HeaderStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const LicensePlateStyles = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
}));

const FooterStyles = styled(Box)(() => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  gap: '6px',
}));

const CarrierNameStyles = styled(Box)(({ theme }) => ({
  maxWidth: 180,
  ...theme.typography.tiny,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

type Props = {
  data: any;
  sx?: SxProps;
};

export const TruckListItem: FC<Props> = ({ data, sx }) => {
  const { t } = useTranslation();

  const addNotePopupState: PopupState = usePopupState({
    variant: 'dialog',
    popupId: 'add_note_popupState',
  });

  return (
    <ContainerStyles sx={{ ...sx }}>
      <ContentStyles>
        <HeaderStyles>
          <LicensePlateStyles>
            <TruckIcon sx={{ width: 16 }} />
            <LicensePlate
              sx={{ marginLeft: 2 }}
              size="medium"
              countryCode=""
              number={data.originalData.licensePlate}
            />
          </LicensePlateStyles>
          <Button
            variant={'text'}
            sx={{ minWidth: '20px', height: '20px', padding: 0 }}
            onClick={() => addNotePopupState.open()}
          >
            <AddCommentIcon sx={{ width: '20px', height: '20px' }} />
          </Button>
        </HeaderStyles>

        <FooterStyles>
          <CarrierNameStyles>{data.originalData.carrierName}</CarrierNameStyles>
          <Tooltip
            title={
              <Box sx={{ textAlign: 'left' }}>
                {data.originalData?.vehicleType ? (
                  <Typography variant={'bodyRegularSmall'}>
                    {t('vehicleType')}:{' '}
                    {getVehicleTypeLabel(data.originalData.vehicleType)}
                  </Typography>
                ) : null}
                <br />
                {data.originalData?.contract ? (
                  <Typography variant={'bodyRegularSmall'}>
                    {t('contractType')}: {data.originalData.contract}
                  </Typography>
                ) : null}
              </Box>
            }
            placement={'right'}
          >
            <InfoOutlineIcon
              sx={{
                width: '16px',
                height: '16px',
              }}
            />
          </Tooltip>
        </FooterStyles>
      </ContentStyles>
      <AddNoteDialog
        popupState={addNotePopupState}
        vehicle={data.originalData.uuid}
      />
    </ContainerStyles>
  );
};
