import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import { DateRange } from '@mui/x-date-pickers-pro';
import {
  CarrierContactType,
  ISODateType,
  Note,
  OrderType,
  PaginatedListType,
  ParamsWithPagination,
  TourComment,
  TourTodosTasksType,
  TourTodoType,
  TourType,
  TransportContractType,
  UUIDType,
} from '@types';
import { convertCamelCaseKeysToSnakeCase } from '@utils';
import { Dayjs } from 'dayjs';

export const getTours = async (
  params: ParamsWithPagination<{
    hasRoute?: boolean;
    hasVehicle?: boolean;
    scheduled_after?: ISODateType;
    scheduled_before?: ISODateType;
    search?: string;
    status?: Array<string>;
    owning_department?: Array<UUIDType>;
    vehicle?: Array<UUIDType>;
  }>
) => {
  const res = await axiosAuthenticatedRequest.get<PaginatedListType<TourType>>(
    '/v2/tours/',
    {
      params: { ...params },
      paramsSerializer: {
        indexes: null, // by default: false
      },
    }
  );
  return res.data;
};

export const getTour = async (tourUUID: string): Promise<TourType> => {
  const res = await axiosAuthenticatedRequest.get(`/v2/tours/${tourUUID}/`);
  return res.data;
};

export const updateTour = async (data: any): Promise<any> => {
  const { tourUUID, tourData } = data;
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/tours/${tourUUID}/`,
    tourData
  );
  return res.data;
};

export const createTransportContract = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<OrderType>(
    `/v2/tours/${tourUUID}/contracts/`
  );
  return res.data;
};

export const getTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
): Promise<TransportContractType> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/`
  );
  return res.data;
};

export const getTransportContractPreview = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/pdf-preview/`
  );
  return res.data;
};

export const getTransportContracts = async (
  tourUUID: UUIDType
): Promise<TransportContractType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contracts/`
  );
  return res.data;
};

export const sendTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/send/`
  );
  return res.data;
};

export const recreateTransportContract = async (
  tourUUID: UUIDType,
  transportContractUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contracts/${transportContractUUID}/recreate/`
  );
  return res.data;
};

export const reviewTour = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<TourType>(
    `/v2/tours/${tourUUID}/review/`
  );
  return res.data;
};

export const connectApproachStop = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<void>(
    `/v2/tours/${tourUUID}/connect/`
  );
  return res.data;
};

export const disconnectApproachStop = async (tourUUID: UUIDType) => {
  const res = await axiosAuthenticatedRequest.post<TourType>(
    `/v2/tours/${tourUUID}/disconnect/`
  );
  return res.data;
};

/* Tour Contacts -----------------------------------------------------------*/

export const getTourContacts = async (
  tourUUID: UUIDType
): Promise<CarrierContactType[]> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/contacts/`
  );
  return res.data;
};

export const createTourContact = async (tourUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/contacts/`,
    data
  );
  return res.data;
};

export const deleteTourContact = async (
  tourUUID: UUIDType,
  contactUUID: UUIDType
) => {
  const res = await axiosAuthenticatedRequest.delete<void>(
    `/v2/tours/${tourUUID}/contacts/${contactUUID}`
  );
  return res.data;
};

/* Tour Comments --------------------------------------------------------*/

export const getTourComments = async (tourUUID: UUIDType, params?: object) => {
  const res = await axiosAuthenticatedRequest.get<
    PaginatedListType<TourComment>
  >(`/v2/tours/${tourUUID}/comments/`, {
    params: params,
    paramsSerializer: {
      indexes: null, // by default: false
    },
  });
  return res.data;
};

export const createTourComment = async (tourUUID: UUIDType, data) => {
  const res = await axiosAuthenticatedRequest.post(
    `/v2/tours/${tourUUID}/comments/`,
    data
  );
  return res.data;
};

/* Tour Todos -----------------------------------------------------------*/

export const getTourTodos = async (
  tourUUID: UUIDType
): Promise<Array<TourTodoType>> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/todos/`
  );
  return res.data;
};

export const getTourTasks = async (
  tourUUID: UUIDType
): Promise<TourTodosTasksType> => {
  const res = await axiosAuthenticatedRequest.get(
    `/v2/tours/${tourUUID}/tasks/`
  );
  return convertCamelCaseKeysToSnakeCase(res.data);
};

/* Notes -----------------------------------------------------------*/

export const getNotes = async (params: {
  limit: number;
  dateRange: DateRange<Dayjs>;
  vehicles?: Array<UUIDType>;
}): Promise<PaginatedListType<Note>> => {
  const { limit, dateRange } = params;
  const res = await axiosAuthenticatedRequest.get(`/v2/vehicle-notes/`, {
    params: {
      limit,
      starts_date_after: dateRange[0].format('YYYY-MM-DD'),
      ends_date_before: dateRange[1].format('YYYY-MM-DD'),
    },
  });
  return res.data;
};

export const addNote = async (
  note: string,
  color: string,
  vehicle: UUIDType,
  dateRange: DateRange<Dayjs>
): Promise<void> => {
  const res = await axiosAuthenticatedRequest.post(`/v2/vehicle-notes/`, {
    note,
    color,
    vehicle,
    starts_date: dateRange[0].format('YYYY-MM-DD'),
    ends_date: dateRange[1].format('YYYY-MM-DD'),
  });
  return res.data;
};

export const editNote = async (
  noteUUID: UUIDType,
  note: string,
  color: string,
  dateRange: DateRange<Dayjs>
): Promise<void> => {
  const res = await axiosAuthenticatedRequest.patch(
    `/v2/vehicle-notes/${noteUUID}/`,
    {
      note,
      color,
      starts_date: dateRange[0].format('YYYY-MM-DD'),
      ends_date: dateRange[1].format('YYYY-MM-DD'),
    }
  );
  return res.data;
};

export const deleteNote = async (noteUUID: UUIDType): Promise<void> => {
  const res = await axiosAuthenticatedRequest.delete(
    `/v2/vehicle-notes/${noteUUID}/`
  );
  return res.data;
};
