import { ArrowRightIcon, colors, Tooltip } from '@dizzbo/ui';
import { SxProps } from '@mui/material';
import Popover from '@mui/material/Popover';
import { DateRange } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import {
  bindPopover,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks';
import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '../Box';
import { Button } from '../Button';
import { Divider } from '../Divider';
import { CloseIcon, DateIcon, SwitchIcon } from '../icons';
import { Stack } from '../Stack';
import { Typography } from '../Typography';

const ArrowButtonStyles: SxProps = {
  width: '32px',
  height: '32px',
  minWidth: '32px',
  borderRadius: '100%',
  padding: '0',
};

type MoveDirection = 'left' | 'center' | 'right';

type ButtonContentProps = {
  buttonLabel: string;
  dateRange: DateRange<Dayjs>;
};

type Props = {
  label: string;
  dateRangeValue: DateRange<Dayjs | null>;
  onChange: (dateRange: DateRange<Dayjs>, newDirection: MoveDirection) => void;
  buttonStyles?: SxProps;
  isButtonContentDisabled?: boolean;
  isSwitchIconDisabled?: boolean;
  resetValue: DateRange<Dayjs>;
  monthsLimit: {
    back: number;
    forward: number;
  };
};

export const MonthRangeSelector: FC<Props> = ({
  label,
  dateRangeValue,
  onChange,
  buttonStyles = {
    height: 40,
  },
  isButtonContentDisabled = false,
  isSwitchIconDisabled = false,
  resetValue,
  monthsLimit,
}) => {
  const { t } = useTranslation();
  const popupState = usePopupState({
    variant: 'popover',
    popupId: `${label}monthRangeSelector`,
  });

  const { isBackButtonEnabled, isForwardButtonEnabled } = useMemo(() => {
    const isBackButtonEnabled: boolean =
      dayjs().diff(dateRangeValue[0], 'months') < monthsLimit.back;
    const isForwardButtonEnabled: boolean =
      dateRangeValue[1].diff(dayjs(), 'months') < monthsLimit.forward;

    return { isBackButtonEnabled, isForwardButtonEnabled };
  }, [dateRangeValue]);

  function handleMonthChange(direction: 'back' | 'forward'): void {
    if (direction === 'back') {
      onChange(
        [
          dateRangeValue[0]
            .subtract(1, 'month')
            .startOf('month')
            .add(1, 'hour'),
          dateRangeValue[1],
        ],
        'left'
      );
    } else {
      onChange(
        [
          dateRangeValue[0],
          dateRangeValue[1]
            .add(2, 'month')
            .startOf('month')
            .subtract(1, 'day')
            .add(1, 'hour'),
        ],
        'right'
      );
    }
  }

  function handleReset(): void {
    onChange(resetValue, 'center');
  }

  return (
    <>
      <Button
        variant={'tertiary'}
        size="medium"
        sx={{ ...buttonStyles, gap: '6px' }}
        {...bindTrigger(popupState)}
      >
        <DateIcon sx={{ height: '16px', width: '16px' }} />
        <ButtonContent
          buttonLabel={!isButtonContentDisabled ? label : ''}
          dateRange={dateRangeValue}
        />
        {!isSwitchIconDisabled ? (
          <SwitchIcon sx={{ height: '16px', width: '16px' }} />
        ) : null}
      </Button>
      <Popover
        {...bindPopover(popupState)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '12px 24px',
            }}
          >
            <Typography
              variant={'buttonRegularSmall'}
              color={colors.turquoise[150]}
            >
              {label}
            </Typography>
            <Button variant={'text'} onClick={() => popupState.close()}>
              <CloseIcon
                sx={{
                  width: '22px',
                  height: '22px',
                  color: colors.oxford['100'],
                }}
              />
            </Button>
          </Box>
          <Divider variant={'fullWidth'} />
          <Stack
            spacing={3}
            sx={{
              alignItems: 'center',
              justifyContent: 'flex',
              padding: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              <Tooltip
                title={
                  <Typography variant={'bodyRegular'}>
                    {isBackButtonEnabled
                      ? t('displayOneMoreMonthBack')
                      : t('monthLimitReachedBack', { limit: monthsLimit.back })}
                  </Typography>
                }
                placement={'bottom'}
              >
                <span>
                  <Button
                    variant={'secondary'}
                    size={'medium'}
                    onClick={() => handleMonthChange('back')}
                    disabled={!isBackButtonEnabled}
                    sx={{ ...ArrowButtonStyles }}
                  >
                    <ArrowRightIcon
                      sx={{ width: '16px', height: '16px', rotate: '180deg' }}
                    />
                  </Button>
                </span>
              </Tooltip>
              <Typography
                variant={'buttonRegularSmall'}
                sx={{ color: colors.turquoise[150] }}
              >
                {`${dateRangeValue[0].format('MMM YYYY')} - ${dateRangeValue[1].format('MMM YYYY')}`}
              </Typography>
              <Tooltip
                title={
                  <Typography variant={'bodyRegular'}>
                    {isForwardButtonEnabled
                      ? t('displayOneMoreMonthAhead')
                      : t('monthLimitReachedAhead', {
                          limit: monthsLimit.forward,
                        })}
                  </Typography>
                }
                placement={'bottom'}
              >
                <span>
                  <Button
                    variant={'secondary'}
                    size={'medium'}
                    onClick={() => handleMonthChange('forward')}
                    disabled={!isForwardButtonEnabled}
                    sx={{ ...ArrowButtonStyles }}
                  >
                    <ArrowRightIcon sx={{ width: '16px', height: '16px' }} />
                  </Button>
                </span>
              </Tooltip>
            </Box>
            <Box>
              <Button
                variant="tertiary"
                size="medium"
                onClick={() => handleReset()}
              >
                {t('reset')}
              </Button>
            </Box>
          </Stack>
        </Box>
      </Popover>
    </>
  );
};

MonthRangeSelector.displayName = 'MonthRangeSelector';

const ButtonContent: FC<ButtonContentProps> = ({ buttonLabel, dateRange }) => {
  if (buttonLabel === '') {
    return null;
  }

  if (
    dateRange[0] !== null &&
    dateRange[0].isValid() &&
    dateRange[1] !== null &&
    dateRange[1].isValid()
  ) {
    return (
      <Stack>
        <Typography variant={'subtitle2'} textAlign={'start'}>
          {buttonLabel}
        </Typography>
        <Typography variant={'buttonRegularSmall'}>
          {`${dateRange[0].format('MMM YYYY')} - ${dateRange[1].format('MMM YYYY')}`}
        </Typography>
      </Stack>
    );
  }
  return <Stack>{buttonLabel}</Stack>;
};
