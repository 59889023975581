import { axiosAuthenticatedRequest } from '@dizzbo/core/api';
import {
  DepartmentType,
  PaginatedListType,
  TeammateType,
  TeamType,
} from '@types';

export const getDepartments = async (
  params = ''
): Promise<PaginatedListType<DepartmentType>> => {
  const res = await axiosAuthenticatedRequest.get('/v2/departments/');
  return res.data;
};

export const getTeams = async (
  params = ''
): Promise<PaginatedListType<TeamType>> => {
  const res = await axiosAuthenticatedRequest.get('/v2/teams/');
  return res.data;
};

export const getColleagues = async (
  department: DepartmentType
): Promise<PaginatedListType<TeammateType>> => {
  const res = await axiosAuthenticatedRequest.get('/v2/colleagues/', {
    params: { department: department?.uuid },
  });
  return res.data;
};
