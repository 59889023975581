import { updateTour } from '@core/api';
import { QueryKeys } from '@core/config';
import { addSelfClearingLoadingToast } from '@dizzbo/core';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LoadingButton,
  SquareIcon,
  Stack,
  Typography,
} from '@dizzbo/ui';
import { styled } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { TourType, UUIDType } from '@types';
import { AxiosError } from 'axios';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useSchedulerSettings } from '../../context';

type VehicleMutationType = {
  vehicle: UUIDType;
};

type Props = {
  popupState: PopupState;
};

const BoxSubtitlesContainer = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.styles.divider.primary.default.borderColor}`,
  padding: 24,
}));

const CardContentContainer = styled(Card)(() => ({
  width: '10  0%',
  height: 200,
}));

export const VehicleAsssignmentDialog: FC<Props> = ({ popupState }) => {
  const { t } = useTranslation();
  const {
    refetchTours,
    refetchNotes,
    isToursDataFetching,
    refetchOrders,
    clearSelection,
    selectedOrder,
    selectedTour,
    targetVehicle,
  } = useSchedulerSettings();
  const tourUUID: UUIDType = selectedTour?.uuid || '';
  const licensePlate: string = targetVehicle?.licensePlate || '-';

  const tourReference = selectedTour?.reference || '';
  const tourStatus = selectedTour?.status || 'DRAFT';
  const { mutate: mutateTour, isPending: isMutating } = useMutation<
    TourType,
    AxiosError,
    VehicleMutationType
  >({
    mutationKey: [QueryKeys.TOURS, tourUUID],
    mutationFn: (values: VehicleMutationType) =>
      updateTour({ tourUUID: tourUUID, tourData: values }),
  });

  async function handleConfirm(): Promise<void> {
    const getToastId = addSelfClearingLoadingToast(t('assigningVehicle'), 6000);

    mutateTour(
      { vehicle: targetVehicle.uuid },
      {
        onSuccess: () => {
          toast.update(getToastId(), {
            closeButton: true,
            autoClose: 2000,
            type: 'success',
            render: selectedOrder
              ? t('tourVehicleSucessfullyAssigned')
              : t('tourVehicleSucessfullyReassigned'),
            isLoading: false,
          });
        },
        onError: () => {
          toast.update(getToastId(), {
            closeButton: true,
            autoClose: 6000,
            render: selectedOrder
              ? t('tourVehicleCouldNotBeAssigned')
              : t('tourVehicleCouldNotBeReassigned'),
            type: 'error',
            isLoading: false,
          });
        },
        onSettled: async () => {
          try {
            await Promise.all([
              refetchTours(),
              refetchOrders(),
              refetchNotes(),
            ]);
            popupState.close();
            clearSelection();
          } catch (error) {
            toast.error(t('somethingWentWrong'));
          }
        },
      }
    );
  }

  async function handleCancel(): Promise<void> {
    if (isMutating) {
      return;
    }
    try {
      await Promise.all([refetchTours(), refetchOrders(), refetchNotes()]);
      popupState.close();
      clearSelection();
    } catch (error) {
      toast.error(t('somethingWentWrong'));
    }
  }

  return selectedTour && targetVehicle ? (
    <Dialog
      PaperProps={{
        elevation: 6,
        variant: 'filled-primary',
        sx: {
          minWidth: 600,
        },
      }}
      scroll="paper"
      {...bindDialog(popupState)}
      onClose={handleCancel}
    >
      <DialogTitle onClose={() => handleCancel()}>
        <Typography variant="h3" color="primary">
          {tourReference}
        </Typography>
        <Typography
          variant="h3"
          color="warning.main"
          sx={{ textTransform: 'capitalize', marginLeft: 1 }}
        >
          – {tourStatus}
        </Typography>
      </DialogTitle>

      <DialogContent dividers sx={{ padding: 0 }}>
        <BoxSubtitlesContainer>
          <Typography variant="h5">
            {selectedOrder
              ? t('assignVehicleToTour')
              : t('reassignTourVehicle')}
          </Typography>
        </BoxSubtitlesContainer>
        <CardContentContainer variant="filled-green" elevation={0} square>
          <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography variant="bodyRegular">
              {t('assigningVehicleToTour', {
                licensePlate,
                tourReference,
              })}
            </Typography>
            {selectedOrder ? (
              <Typography variant="bodyRegular">
                {t('selectedOrderReference')}:{' '}
                {selectedOrder?.reference || t('orderReferenceMissing')}
              </Typography>
            ) : null}
          </CardContent>
        </CardContentContainer>
      </DialogContent>
      <DialogActions>
        <Stack spacing={3} direction="row">
          <Button variant="tertiary" onClick={() => handleCancel()}>
            {t('cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="primary"
            onClick={() => handleConfirm()}
            disabled={isToursDataFetching || isMutating}
            loading={isToursDataFetching || isMutating}
            startIcon={<SquareIcon />}
          >
            {!isToursDataFetching ? t('assign') : t('loading')}
          </LoadingButton>
        </Stack>
      </DialogActions>
    </Dialog>
  ) : null;
};
