import { TabNavigation } from '@dizzbo/core/components/Workspaces';
import {
  Box,
  Divider,
  LoadingScreen,
  PaginationState,
  TablePaginationBar,
} from '@dizzbo/ui';
import { Grid, styled } from '@mui/material';
import React, { FC } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useSchedulerSettings } from '../../context';
import { OrderFilters } from '../OrderFilters';
import { OrderList } from '../OrderList';
import { Scheduler } from '../Scheduler';
import { Toolbar } from '../Toolbar';

const GridContentContainer = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflowX: 'hidden',
  overflowY: 'hidden',
}));

const GridSchedulerContainer = styled(Grid)(() => ({
  height: '100%',
  width: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
}));

const BoxOrdersContainer = styled(Box)(({ theme }) => ({
  height: '120px',
  boxShadow: theme.shadows[2],
}));

const BoxPagination = styled(Box)(() => ({
  height: '80px',
}));

type Props = {};

export const SchedulerContent: FC<Props> = () => {
  const { t } = useTranslation();

  const {
    isFetching,
    ordersCount,
    ordersPaginationState,
    updateOrdersPagination,
    refetchOrders,
  } = useSchedulerSettings();

  function handleSetPagination(newPaginationState: PaginationState): void {
    updateOrdersPagination(newPaginationState);
    refetchOrders();
  }

  if (isFetching) {
    return <LoadingScreen sx={{ position: 'absolute' }} />;
  }

  return (
    <TabNavigation sx={{ paddingTop: '6px' }}>
      <DndProvider backend={HTML5Backend}>
        <GridContentContainer>
          <Toolbar />
          <GridSchedulerContainer>
            <Scheduler />
          </GridSchedulerContainer>
          <OrderFilters />
          <Divider variant={'fullWidth'} />
          <BoxOrdersContainer>
            <OrderList />
          </BoxOrdersContainer>
          <BoxPagination>
            <TablePaginationBar
              rowCount={ordersCount}
              isLoading={isFetching}
              pagination={ordersPaginationState}
              labelRowsPerPage={t('ordersPerPage')}
              setPagination={handleSetPagination}
              sx={{
                height: '100%',
              }}
            />
          </BoxPagination>
        </GridContentContainer>
      </DndProvider>
    </TabNavigation>
  );
};
